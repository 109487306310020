import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, Link } from "gatsby"
import MainContainer from "../components/main-container"
import styled, { keyframes } from "styled-components"
import { Helmet } from "react-helmet"

const FrontpageCapsuleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
`

var morph = keyframes`
  from {
    border-radius:
    ${props => props.randomPerc3}
    ${props => props.randomPerc3}
    ${props => props.randomPerc3}
    ${props => props.randomPerc4} /
    ${props => props.randomPerc4}
    ${props => props.randomPerc3}
    ${props => props.randomPerc2}
    ${props => props.randomPerc1};
  }
  to {
    border-radius:
    60%
    70%;
    }
`

const FrontpageCapsule = styled.div`
  background-image: url(${props => props.imageURL});
  background-position: center;
  background-size: 130%;
  border: 10px solid transparent;
  position: relative;
  border-radius: ${props => props.randomPerc1} ${props => props.randomPerc2}
    ${props => props.randomPerc3} ${props => props.randomPerc4};
  animation: ${morph} ${props => props.randomDelay} ease-in-out infinite both
    alternate;
  transition: border 0.05s ease-in-out;

  &:hover {
    border-color: ${props => props.theme.colors.brandColorBright};
    transition: border 0.05s ease-in-out;
  }
  &::before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }
`
const FrontpageCapsuleTitle = styled.div`
  position: relative;
  bottom: 0.5em;
  left: 0.5em;

  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.h3};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  text-shadow: 0px 0px 20px rgb(0 0 0), 0px 0px 20px rgb(0 0 0),
    0px 0px 20px rgb(0 0 0), 0px 0px 20px rgb(0 0 0);
`

const OldGamesHeader = styled.div`
  display: block;
  margin-top: 1.5em;
  margin-bottom: 0.15em;

  font-family: ${props => props.theme.fonts.ghostbutterFont};
  font-size: ${props => props.theme.fontSizes.headerLogo};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

function checkIfMainGame(game) {
  return game.node.frontmatter.gamecategory === "main"
}

function checkIfOldGame(game) {
  return game.node.frontmatter.gamecategory === "old"
}

const GameIndex = ({ data }) => {
  const { edges: games } = data.allMdx
  const mainGames = games.filter(checkIfMainGame)
  const oldGames = games.filter(checkIfOldGame)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Ghostbutter - Spooky-cute, bone-licking good games - Games
        </title>
        <link rel="canonical" href="https://ghostbutter.com/" />
      </Helmet>
      <Header></Header>
      <MainContainer>
        <FrontpageCapsuleContainer>
          {mainGames.map(({ node: game }) => (
            <Link key={game.id} to={game.frontmatter.slug}>
              <FrontpageCapsule
                imageURL={game.frontmatter.frontpageimage}
                randomDelay={Math.floor(Math.random() * 2.5) + 1.5 + "s"}
                randomPerc1={Math.floor(Math.random() * 80) + 40 + "%"}
                randomPerc2={Math.floor(Math.random() * 80) + 40 + "%"}
                randomPerc3={Math.floor(Math.random() * 80) + 40 + "%"}
                randomPerc4={Math.floor(Math.random() * 80) + 40 + "%"}
              >
                <FrontpageCapsuleTitle>
                  {game.frontmatter.title}
                </FrontpageCapsuleTitle>
              </FrontpageCapsule>
            </Link>
          ))}
        </FrontpageCapsuleContainer>
        <OldGamesHeader>Older Projects</OldGamesHeader>
        <FrontpageCapsuleContainer>
          {oldGames.map(({ node: game }) => (
            <Link key={game.id} to={game.frontmatter.slug}>
              <FrontpageCapsule
                imageURL={game.frontmatter.frontpageimage}
                randomDelay={Math.floor(Math.random() * 2.5) + 1.5 + "s"}
                randomPerc1={Math.floor(Math.random() * 80) + 40 + "%"}
                randomPerc2={Math.floor(Math.random() * 80) + 40 + "%"}
                randomPerc3={Math.floor(Math.random() * 80) + 40 + "%"}
                randomPerc4={Math.floor(Math.random() * 80) + 40 + "%"}
              >
                <FrontpageCapsuleTitle>
                  {game.frontmatter.title}
                </FrontpageCapsuleTitle>
              </FrontpageCapsule>
            </Link>
          ))}
        </FrontpageCapsuleContainer>
      </MainContainer>
      <Footer></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query gameIndex {
    allMdx(sort: { fields: [frontmatter___releasedate], order: DESC }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            slug
            frontpageimage
            gamecategory
          }
        }
      }
    }
  }
`

export default GameIndex
